import { Button, Caption2, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Dropdown, Option, Text, type DialogOpenChangeData, type DialogOpenChangeEvent } from "@fluentui/react-components";
import type { Organization } from "../../../client";
import React from "react";

interface OrganizationVerificationDialogProps {
    open: boolean;
    organization: Organization;
    onDismiss?: (reason: "cancel" | "submit") => void;
}

type NavHandler = (direction: "forward" | "backward") => void;

const step1 = (organization: Readonly<Organization>, navCallback: NavHandler) => {
    const handleNextClick = () => {
        navCallback("forward");
    };

    return (
        <DialogBody>
            <DialogTitle>Verify {organization.name}</DialogTitle>
            <DialogContent>
                <Text>For verification, you'll need to show that <b>{organization.name}</b> is real and you have a legitimate connection to it.</Text>
                <Text>
                    <ul>
                        <li><b>Verify business details</b><br />Provide your business name, address, phone number, email and website for verification.</li>
                        <li><b>Confirm your connection</b><br />Select a way for us to get in touch to confirm your connection to the business.</li>
                        <li><b>Upload documents</b><br />You may need to upload accepted documents to confirm these details if your business is not found.</li>
                    </ul>
                </Text>
                <DialogActions>
                    <DialogTrigger action="close">
                        <Button>Cancel</Button>
                    </DialogTrigger>
                    <Button appearance="primary" onClick={handleNextClick}>Get started</Button>
                </DialogActions>
                <Caption2>Any documents or information you submit to verify your business will be used to help us comply with our legal obligations and to prevent misuse, for example impersonation or fraud. This helps protect you and the rest of our community.\nThe documents you submit will be stored for one year, and longer in rare instances. In certain cases, we'll update your publicly available Page Transparency information with some of the details you confirm during verification. You may also have the option to make these details visible.</Caption2>
            </DialogContent>
        </DialogBody>
    );
}

const step2 = (organization: Readonly<Organization>, navCallback: NavHandler) => {
    const handleBackClick = () => {
        navCallback("backward");
    };

    const handleNextClick = () => {
        navCallback("forward");
    };

    return (
        <DialogBody>
            <DialogTitle>Verify {organization.name}</DialogTitle>
            <DialogContent>
                <div>
                    <Dropdown>
                        <Option value="US">United States</Option>
                    </Dropdown>
                </div>
                <DialogActions>
                    <Button onClick={handleBackClick}>Back</Button>
                    <Button appearance="primary" onClick={handleNextClick}>Next</Button>
                </DialogActions>
                <Caption2>Any documents or information you submit to verify your business will be used to help us comply with our legal obligations and to prevent misuse, for example impersonation or fraud. This helps protect you and the rest of our community.\nThe documents you submit will be stored for one year, and longer in rare instances. In certain cases, we'll update your publicly available Page Transparency information with some of the details you confirm during verification. You may also have the option to make these details visible.</Caption2>
            </DialogContent>
        </DialogBody>
    );
};

const InnerDialog: React.FC<OrganizationVerificationDialogProps> = (props: Readonly<OrganizationVerificationDialogProps>) => {
    const { open, organization, onDismiss } = props;
    const [currentStep, setCurrentStep] = React.useState<number>(1);

    const handleNavigation = React.useCallback((direction: "forward" | "backward") => {
        if (direction === "forward") {
            setCurrentStep((prev) => prev + 1);
        } else {
            setCurrentStep((prev) => prev === 1 ? prev : prev - 1);
        }
    }, []);

    const renderStep = React.useCallback((step: number) => {
        switch (step) {
            case 1:
                return step1(organization, handleNavigation);
            case 2:
                return step2(organization, handleNavigation);
            default:
                return null;
        }
    }, [handleNavigation, organization]);

    const handleOpenChange = React.useCallback((_event: DialogOpenChangeEvent, data: DialogOpenChangeData) => {
        if (data.type === "triggerClick") {
            onDismiss?.("cancel");
        }
    }, [onDismiss]);

    return (
        <Dialog
            modalType="modal"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <DialogSurface>
                {renderStep(currentStep)}
            </DialogSurface>
        </Dialog>
    );
};

export const VerifyOrganizationDialog = (props: Readonly<OrganizationVerificationDialogProps>) => {
    const { open } = props;

    if (open) {
        return <InnerDialog {...props} />;
    } else {
        return null;
    }
};

export default VerifyOrganizationDialog;