import { Button, Spinner, type ButtonProps } from "@fluentui/react-components";
import React from "react";

type ButtonWithLoaderProps = ButtonProps & {
    loading?: boolean;
}

export const ButtonWithLoader: React.FC<ButtonWithLoaderProps> = (props: Readonly<ButtonWithLoaderProps>) => {
    const { loading, ...buttonProps } = props;

    const renderSpinner = React.useCallback(() => {
        return <Spinner size="tiny" />;
    }, []);

    return (
        <Button
            {...buttonProps}
            disabled={loading || buttonProps.disabled}
            icon={loading ? renderSpinner() : buttonProps.icon}
            iconPosition={loading ? "before" : buttonProps.iconPosition}
        />
    );
};

export default ButtonWithLoader;