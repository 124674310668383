import { Input, Label, type InputOnChangeData } from "@fluentui/react-components";
import React from "react";

export interface AddOrganizationFormValues {
    name?: string;
    addressStreetAddress?: string;
    addressLocality?: string;
    addressRegion?: string;
    addressCountry?: string;
}

export type AddOrganizationFormErrors = Partial<Record<keyof AddOrganizationFormValues, string>>;

interface AddOrganizationFormProps {
    errors?: AddOrganizationFormErrors;
    values?: Partial<AddOrganizationFormValues>;
    onChange?: (values: Partial<AddOrganizationFormValues>) => void;
}

export const AddOrganizationForm = (props: Readonly<AddOrganizationFormProps>) => {
    const { values, onChange } = props;
    const valuesRef = React.useRef(values);

    const handleInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const inputName = (event.currentTarget ?? event.target).name;
        if (onChange && inputName) {
            onChange({
                ...valuesRef.current,
                [inputName]: data.value,
            });
        }
    }, [onChange]);

    return (
        <form>
            <div>
                <Label htmlFor="name">Organization name</Label>
                <Input
                    defaultValue={valuesRef.current?.name}
                    name="name"
                    placeholder="Company Name, LLC."
                    value={values?.name}
                    onChange={handleInputChange}
                />
            </div>
        </form>
    )
};

export default AddOrganizationForm;