import type { Organization } from "../../../client";

interface OrganizationListProps {
    items: ReadonlyArray<Organization>;
    onListItemClick?: (organization: Organization) => void;
}

export const OrganizationList = (props: Readonly<OrganizationListProps>) => {
    const { items = [], onListItemClick } = props;

    const handleListItemClick = (organization: Organization) => {
        onListItemClick?.(organization);
    };

    return (
        <div>
            {items.map((organization) => (
                <div key={organization.id} onClick={() => handleListItemClick(organization)}>{organization.name}</div>
            ))}
        </div>
    );
};

export default OrganizationList;