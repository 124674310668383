import { AddOrganizationDialog, OrganizationList, VerifyOrganizationDialog } from "../components";
import { useEffect, useState } from "react";

import { Button } from "@fluentui/react-components";
import type { Organization } from "../client";
import React from "react";
import { serviceClient } from "../serviceClient";

export const OrganizationsPage = () => {
    const [nextLink, setNextLink] = useState<string | undefined>(undefined);
    const [organizations, setOrganizations] = useState<ReadonlyArray<Organization>>([]);
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>(undefined);

    const getOrganizations = () => {
        serviceClient.getOrganizations()
            .then((response) => {
                setOrganizations(response.value ?? []);
                setNextLink(response["odata.nextLink"]);
            });
    };

    const handleLoadMoreBtnClick = () => {
    };

    const handleAddBtnClick = () => {
        setAddDialogOpen(true);
    };

    const handleAddDialogDismiss = React.useCallback((reason: "cancel" | "submit") => {
        if (reason === "submit") {
            getOrganizations();
        }

        setAddDialogOpen(false);
    }, []);

    const handleVerifyDialogDismiss = React.useCallback((reason: "cancel" | "submit") => {
        setSelectedOrganization(undefined);
    }, []);

    const handleListItemClick = React.useCallback((organization: Organization) => {
        setSelectedOrganization(organization);
    }, []);

    useEffect(() => {
        getOrganizations();
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
                <Button
                    appearance="primary"
                    onClick={handleAddBtnClick}
                >
                    Add
                </Button>
            </div>
            <OrganizationList
                items={organizations}
                onListItemClick={handleListItemClick}
            />
            {nextLink && (
                <Button onClick={handleLoadMoreBtnClick}>Load more</Button>
            )}
            <AddOrganizationDialog
                open={addDialogOpen}
                onDismiss={handleAddDialogDismiss}
            />
            {selectedOrganization && (
                <VerifyOrganizationDialog
                    open={Boolean(selectedOrganization)}
                    organization={selectedOrganization}
                    onDismiss={handleVerifyDialogDismiss}
                />
            )}
        </div>
    );
};

export default OrganizationsPage;