import { Navigate, Route, Routes } from "react-router-dom";

import CertifyPage from "../pages/CertifyPage";
import DashboardPage from "../pages/DashboardPage";
import EntityClaimPage from "../pages/EntityClaimPage";
import { MainLayout } from "../layouts";
import { OrganizationsPage } from "../pages";
import routePaths from "./routePaths";

export const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route path={routePaths.dashboard} element={<DashboardPage />} />
                <Route path={routePaths.credentials.add} element={<CertifyPage />} />
                <Route path={routePaths.organizations.add} element={<EntityClaimPage />} />
                <Route path={routePaths.organizations.list} element={<OrganizationsPage />} />
            </Route>
            <Route index path={routePaths.index} element={<Navigate to={routePaths.dashboard} replace={true} />} />
        </Routes>
    )
};

export default AppRoutes;