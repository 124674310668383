import type { AddOrganizationRequest, CollectionResponse, Organization } from "./models";

export class ServiceClient {
    private _baseUrl: string;
    constructor(baseUrl = "https://credentialmy.biz/api") {
        this._baseUrl = baseUrl;
    }

    public async addOrganization(request: AddOrganizationRequest): Promise<Organization> {
        const requestUrl = `${this._baseUrl}/organizations/add`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request)
        });
        const organization = await response.json();
        return organization;
    }

    public async getOrganizations(): Promise<CollectionResponse<Organization>> {
        const requestUrl = `${this._baseUrl}/organizations/list`;
        const response = await fetch(requestUrl);
        const collectionResponse = await response.json();
        return collectionResponse;
    }
}

export default ServiceClient;